import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, TextField, FormControl, Chip, Autocomplete, ListItemText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';
import { tr } from 'date-fns/locale';

function AssignCustomerAndEmployeeModal(props) {
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]); // State for selected employees
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [notes, setNotes] = useState('');
  const [sessionNotes, setSessionNotes] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    selectedCustomers: false,
    selectedEmployees: false,
    startTime: false,
    endTime: false
  });
  const [customersOpen, setCustomersOpen] = useState(false);

  const isAdmin = props.isAdmin; // Using passed prop to check if the user is an admin

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    let scheduledSessions = props.sessions.filter(session => session.schedule_id === props.schedule.schedule_id);
    let scheduleCustomers = scheduledSessions.map(session => session.customer_id);

    if(selectedCustomers.length + scheduleCustomers.length > props.schedule.max_capacity) {
      props.onError("The number of customers assigned to the session exceeds the maximum capacity");
      return;
    }

    let sday = startDate ? startDate : props.schedule.date;
    let st = startTime ? startTime : props.schedule.start_time;
    let et = endTime ? endTime : props.schedule.end_time;

    let session = {
      schedule_id: props.schedule.schedule_id,
      customers: selectedCustomers.map(customer => customer.cus_id),
      date: sday,
      start_time: moment(st).date(moment(startDate).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      end_time:  moment(et).date(moment(startDate).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      notes: notes
    }

    let schedule = { 
      date: sday,
      start_time: moment(st).date(moment(sday).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      end_time: moment(et).date(moment(sday).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      notes: sessionNotes,
      resource_id: props.schedule.resource_id,
      max_capacity: props.schedule.max_capacity,
      schedule_name: props.schedule.schedule_name,
      task_type: props.schedule.task_type,
    };
    console.log(schedule, startTime, endTime);

    if(isAdmin) {
      if(selectedEmployees.length > 0) {
        try{
          await axios.post(APIURL + '/master_schedule/employee', { schedule_id: props.schedule.schedule_id, employee_ids: selectedEmployees.map(employee => employee.id) });
          props.onSuccess("Employees/Customers assigned to session successfully");
        } catch (error) {
          props.onError("An error occurred while assigning employees/customers to session");
          return;
        }
      }
    }
    
    if(selectedCustomers.length > 0) {
      try {
        await axios.post(APIURL + '/sessions', session);
        props.onSuccess("Customers assigned to session successfully");
      } catch (error) {
        console.log(error);
          if(error.response) {
            props.onError(error.response.data.message);
          } else {
            props.onError("An error occurred while assigning customers to session");
          }
          return;
      }
    } 

    if(startDate !== props.schedule.date || startTime !== props.schedule.start_time || endTime !== props.schedule.end_time) {
      try {
        await axios.put(APIURL + '/master_schedule/' + props.schedule.schedule_id, schedule);
        props.onSuccess("Schedule updated successfully");
      } catch (error) {
        if(error.response) {
          props.onError(error.response.data.message);
        } else {
          props.onError("An error occurred while updating schedule");
        }
        return;
      }
    }

    props.reloadSessionData();
    props.reloadScheduleData();
    props.onClose();
  };

  const handleDeleteEmployee = async (employee) => {
    try {

      await axios.delete(APIURL + '/master_schedule/employee/' + employee.id, { data: { schedule_id: props.schedule.schedule_id } });
      props.reloadScheduleData();
      props.onSuccess("Employee removed from session successfully");
    } catch (error) {
      console.log(error);
      props.onError("An error occurred while removing employee from session");
    }   
  };

  const handleDeleteCustomer = async (customer) => {
    try {
      let session = props.sessions.find(session => session.schedule_id === props.schedule.schedule_id && session.customer_id === customer.cus_id);
      await axios.delete(APIURL + '/sessions/' + session.session_id);
      props.reloadSessionData();
      props.onSuccess("Customer removed from session successfully");
    } catch (error) {
      props.onError("An error occurred while removing customer from session");
    }
  };

  const validate = () => {
    let errors;
    if(isAdmin) {
      errors = {
        selectedCustomers: selectedCustomers.length === 0 && selectedEmployees.length === 0 && startDate === props.schedule.date && startTime === props.schedule.start_time && endTime === props.schedule.end_time,
        selectedEmployees: selectedEmployees.length === 0 && selectedCustomers.length === 0 && startDate === props.schedule.date && startTime === props.schedule.start_time && endTime === props.schedule.end_time,
        startTime: !startTime || moment(startTime).isSameOrAfter(moment(endTime)),
        endTime: !endTime || moment(endTime).isSameOrBefore(moment(startTime))
      };
    } else {
      errors = {
        selectedCustomers: selectedCustomers.length === 0,
        startTime: !startTime || moment(startTime).isSameOrAfter(moment(endTime)),
        endTime: !endTime || moment(endTime).isSameOrBefore(moment(startTime))
      };
    }
    console.log(errors);
    setErrors(errors);
    setSubmitted(true);
    return !Object.values(errors).some(err => err);
  };

  const unassignedCustomers = () => {
    let scheduledSessions = props.sessions.filter(session => session.schedule_id === props.schedule?.schedule_id);
    let scheduleCustomers = scheduledSessions.map(session => session.customer_id);
    return props.customers.filter(customer => !scheduleCustomers.includes(customer.cus_id));
  }

  const assginedCustomers = () => {
    let scheduledSessions = props.sessions.filter(session => session.schedule_id === props.schedule?.schedule_id);
    let scheduleCustomers = scheduledSessions.map(session => session.customer_id);
    return props.customers.filter(customer => scheduleCustomers.includes(customer.cus_id));
  }

  const unassginedEmployees = () => {
    return props.schedule?.employee_ids ? props.employees.filter(employee => !props.schedule.employee_ids.includes(employee.id)) : props.employees;
  }

  const assginedEmployees = () => {
    return props.schedule?.employee_ids ? props.employees.filter(employee => props.schedule.employee_ids.includes(employee.id)) : [];
  }

  useEffect(() => {
    if (!props.open) {
      setSubmitted(false);
      setErrors({
        selectedCustomers: false,
        selectedEmployees: false,
      });
      setSelectedCustomers([]);
      setSelectedEmployees([]);
      setNotes('');
    } else { 
      setStartDate(props.schedule?.date ? moment(props.schedule?.date.substring(0, 10)) : '');
      setStartTime(props.schedule?.start_time);
      setEndTime(props.schedule?.end_time);
      setSessionNotes(props.schedule?.notes);
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="assign-entity-modal-title"
      aria-describedby="assign-entity-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, maxWidth: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
        <Typography id="assign-entity-modal-title" variant="h6" component="div" sx={{ mb: 1 }}>
          Edit Session
        </Typography>
        <Typography variant="subtitle1">
          {props.schedule?.schedule_name}
        </Typography>
        <Typography variant="subtitle2" component="div" sx={{ mb: 1 }}>
          {props.resources.find(resource => resource.resource_id === props.schedule?.resource_id)?.resource_name} {moment(props.schedule?.date).utc().format('MM/DD/YYYY')} {moment(props.schedule?.start_time).format('hh:mm A')} - {moment(props.schedule?.end_time).format('hh:mm A')}
        </Typography>
        {isAdmin && (
          <>
            <Typography variant="subtitle2" component="div" sx={{ mb: 1 }}>
              Assigned Employees:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {assginedEmployees().map(employee => (
                <Chip key={employee.id} label={employee.employee_name} color="primary" sx={{ mb: .15}} onDelete={() => handleDeleteEmployee(employee)} />
              ))}
            </Box>
            <FormControl fullWidth margin="normal" sx={{ mt: 1, mb: 2 }}>
              <Autocomplete
                multiple
                id="employee-select"
                options={unassginedEmployees()}
                getOptionLabel={(option) => option.employee_name}
                defaultValue={[]}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Employees"
                    placeholder="Employees"
                    error={errors.selectedEmployees && submitted}
                  />
                )}
                onChange={(event, newValue) => {
                  setSelectedEmployees(newValue);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <ListItemText primary={option.employee_name} />
                    </li>
                  )
                }}
              />
            </FormControl>
          </>
        )}

        <Typography variant="subtitle2" component="div" sx={{ mb: 1 }}>
          Assigned Customers:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {assginedCustomers().map(customer => (
            <Chip key={customer.cus_id} label={customer.cus_name} color="primary" sx={{ mb: .15}} onDelete={() => handleDeleteCustomer(customer)} />
          ))}
        </Box>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal" sx={{ mt: 1, mb: 2 }}>
            <Autocomplete
              multiple
              id="customer-select"
              options={unassignedCustomers()}
              getOptionLabel={(option) => option.cus_name}
              defaultValue={[]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Select Customers"
                    placeholder="Customers"
                    error={errors.selectedCustomers && submitted}
                />
              )}
              open={customersOpen}
              onOpen={() => {
                setCustomersOpen(true);
              }}
              onClose={(event, reason) => {
                if (reason === 'selectOption') {
                  setCustomersOpen(true);
                } else {
                  setCustomersOpen(false);
                }
              }}
              onChange={(event, newValue) => {
                setSelectedCustomers(newValue);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.cus_id}>
                    <ListItemText primary={option.cus_name} />
                  </li>
                )
              }}
            />
          </FormControl>
          <Box sx={{ display: 'flex ', flexDirection: 'row', gap: 1, mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={new Date(startDate)}
                sx={{ width: '36%' }}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <TimePicker
                label="Start Time"
                value={startTime}
                slotProps={{ textField: { error: errors.startTime && submitted } }}
                sx={{ width: '32%' }}
                onChange={(newValue) => setStartTime(newValue)}
              />
              <TimePicker
                label="End Time"
                value={endTime}
                slotProps={{ textField: { error: errors.endTime && submitted } }}
                sx={{ width: '32%' }}
                onChange={(newValue) => setEndTime(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <TextField
            margin="normal"
            fullWidth
            label="Session Notes"
            multiline
            rows={4}
            value={sessionNotes}
            onChange={(e) => setSessionNotes(e.target.value)}
            sx={{ mb: 2 }}
          />
{/*           <TextField
            margin="normal"
            fullWidth
            label="Customer Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{ mb: 2 }}
          /> */}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
            Edit Session
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AssignCustomerAndEmployeeModal;

import React, { useEffect, useState } from 'react'
import { APIURL } from '../../config';
import axios from 'axios'
import NoteTile from './NoteTile'
import './recent_note.css'
import moment from 'moment'
import MessagePage from '../MessagePages/MessagePage'
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import NoteFormv2 from "../forms/NoteForm/NoteFormv2"

export default function RecentNotes(props) {

    const [notes, setNotes] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [editNote, setEditNote] = useState(null)
    const [showAddNote, setShowAddNote] = useState(false)

    async function fetchRecentNotes() {
        try {
            const selectedProjects = await axios.get(`${APIURL}/project`, { params: { comp_id: props.user.comp_id, emp_id: props.user.emp_id } })
            let projects = selectedProjects.data

            let projList = []
            projects.forEach(project => {
                if (project.pro_is_active) {
                    projList.push([project.pro_id, project.customer_details[0].cus_name, project.pro_customer_id])
                }
            })

            let commentList = []
            await Promise.all(projList.map(async (proj_id) => {
                const comments = await axios.get(`${APIURL}/comments`, { params: { pro_id: proj_id[0], lastN: 5 } })
                for (let i = 0; i < comments.data.length; i++) {
                    var tmpObj = comments.data[i]
                    tmpObj.cus_name = proj_id[1]
                    tmpObj.cus_id = proj_id[2]
                    commentList.push(tmpObj)
                }
            }))

            commentList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            commentList = commentList.slice(0, 5)

            const reqEmpIDs = []
            for (const comment of commentList) {
                reqEmpIDs.push(comment.emp_id)
            }

            const empList = {}
            const employees = await axios.get(`${APIURL}/employees`, { params: { com_id: props.user.comp_id } })
            let empData = employees.data

            empData.forEach(emp => {
                if (reqEmpIDs.includes(emp.emp_id))
                    empList[emp.emp_id] = [emp.emp_first_name + ' ' + emp.emp_last_name, emp.emp_type]
            })

            const finalResult = []
            for (let i = 0; i < commentList.length; i++) {
                let result_obj = {}
                result_obj.cust_name = commentList[i].cus_name
                result_obj.comment = commentList[i].comment_text
                result_obj.created_by = `${moment(commentList[i].created_at).format("MM/DD/YYYY hh:MM A")} created by ${empList[commentList[i].emp_id][0]} (${empList[commentList[i].emp_id][1]})`
                result_obj.cus_id = commentList[i].cus_id
                result_obj.comment_id = commentList[i].comment_id
                finalResult.push(result_obj)
            }
            // console.log(finalResult)
            setNotes(finalResult)
            setLoading(false)
        }
        catch {
            setNotes([])
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.user) {
            fetchRecentNotes()
        }
    }, [])

    useEffect(() => {
        if (props.user) {
            fetchRecentNotes()
        }
    }, [props.user, editNote, showAddNote])

    return (
        <div className='notes-wrapper'>
            <div className="note-wrapper-top-row">
                <Icon onClick={() => { setShowAddNote(true); }} className="fa fa-plus-circle timesheet-icon" style={{ fontSize: 30, color: '#3FA7D6', cursor: 'pointer' }} />
                <h3 style={{ fontSize: 20, marginTop: 15 }} className="timesheet-wrapper-header">RECENT NOTES</h3>
                <div className="note-subtitle-wrapper">
                    <p className='mt-2 p-1'>Your recent 5 notes:</p>
                </div>
            </div>
            <div style={{ overflowY: 'auto', marginTop: '20px', padding: '10px' }}>
                <div>
                    {!!isLoading &&
                        <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!isLoading && notes.length === 0 &&
                        <div style={{ margin: 30, height: '100%' }} className='d-flex flex-column justify-content-center align-items-center'>
                            {<MessagePage title="No Recent Notes Found" icon="far fa-sticky-note"></MessagePage>}
                        </div>
                    }
                    {
                        notes.map((item, index) => (
                            <div key={index} className='note_tile_container'>
                                <IconButton
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        setEditNote(item)
                                    }}
                                >
                                    <Icon style={{ fontSize: '16px', marginTop: '0.5vh' }} baseClassName="fas" className="fa-edit" />
                                </IconButton>

                                <Dialog fullWidth={true} maxWidth={'sm'} open={editNote != null && item.comment_id == editNote.comment_id}>
                                    <DialogActions>
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            sx={{ p: 0.25 }}
                                            onClick={() => { setEditNote(null) }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogActions>
                                    <DialogContent sx={{ paddingTop: '0px' }}>
                                        <NoteFormv2 mode='edit' checkTokenExpiry={props.checkTokenExpiry} project_id={editNote?.proj_id} task_id={editNote?.tas_id} note_id={editNote?.comment_id} user={props.user} customer_id={item.cus_id} company_id={props.user.comp_id} onSubmit={() => { setEditNote(null) }}></NoteFormv2>
                                    </DialogContent>
                                </Dialog>

                                <NoteTile
                                    name={item.cust_name}
                                    time={item.created_by}
                                    note={item.comment}
                                    cus_id={item.cus_id}
                                />
                            </div>
                        ))
                    }
                    <Dialog fullWidth={true} maxWidth={'sm'} open={showAddNote}>
                        <DialogActions>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.25 }}
                                onClick={() => { setShowAddNote(false) }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogContent sx={{ paddingTop: '0px' }}>
                            <NoteFormv2 mode='add' checkTokenExpiry={props.checkTokenExpiry} company_id={props.user.comp_id} isVoiceNote={false} user={props.user} onSubmit={() => { setShowAddNote(false); }}></NoteFormv2>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

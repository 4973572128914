import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import { Button, Select, MenuItem, TextField, FormControl, InputLabel, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function PermittedTaskForm(props) {
    const [formData, setFormData] = useState({
        customer_id: null,
        customer_goal_id: null,
        taskProcCode_id: null,
        goal_reference: '',
        billable_to: '',
        provider_assigned: '',
        location_assigned: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
        total_cost: 0.00
    });
    const [customers, setCustomers] = useState([])
    const [customerGoals, setCustomerGoals] = useState([])
    const [taskProcCodes, setTaskProcCodes] = useState([])

    useEffect(() => {
        async function fetchCustomers() {
            let customersResponse = (await axios.get(APIURL + `/customers?company_id=${[props.user.comp_id]}`)).data
            customersResponse.sort((a, b) => {
                let nameA = a.customer_name.toUpperCase();
                let nameB = b.customer_name.toUpperCase();
            
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            
                return 0;
            });
            setCustomers(customersResponse)
        }
        async function fetchTaskProcCodes() {
            let taskProcCodeResponse = (await axios.get(APIURL + `/procedureCodes/handleMappingForTaskProcCode?comp_id=${[props.user.comp_id]}`)).data
            setTaskProcCodes(taskProcCodeResponse)
        }

        fetchCustomers();
        fetchTaskProcCodes();
        
        if(props.customer_id){
            formData.customer_id=props.customer_id
        }
        if(props.customer_goal_id){
            formData.customer_goal_id=props.customer_goal_id
        }
    }, []);

    useEffect(() => {
        async function fetchCustomerGoals() {
            const customerGoalsResponse = (await axios.get(APIURL + `/customer_goal?customer_id=${[formData.customer_id]}`)).data
            setCustomerGoals(customerGoalsResponse)
        }
        fetchCustomerGoals();

        async function fetchExistingPermittedTask() {
            const permittedTaskResponse = (await axios.get(APIURL + `/customer_goal/permitted_task`, { params: {permitted_task_id: props.permitted_task_id}})).data[0]
            setFormData({
                ...formData,
                permitted_task_id: props.permitted_task_id,
                customer_id: permittedTaskResponse.customer_id,
                customer_goal_id: permittedTaskResponse.customer_goal_id,
                taskProcCode_id: permittedTaskResponse.taskProcCode_id,
                goal_reference: permittedTaskResponse.goal_reference,
                billable_to: permittedTaskResponse.billable_to,
                provider_assigned: permittedTaskResponse.provider_assigned,
                location_assigned: permittedTaskResponse.location_assigned,
                start_date: moment(permittedTaskResponse.start_date).format('YYYY-MM-DD'),
                end_date: moment(permittedTaskResponse.end_date).add(1, 'day').format('YYYY-MM-DD'),
                total_cost: permittedTaskResponse.total_cost
            });
        }
        if(props.permitted_task_id){
            fetchExistingPermittedTask()
        }

    }, [formData.customer_id]);

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSave = async() => {

        if(formData.customer_id === null || formData.customer_goal_id === null || formData.taskProcCode_id === null || formData.start_date === '' || formData.end_date === '') {
            alert("Please fill out all required fields.")
            return;
        }

        let response = {};
        if(props.permitted_task_id){
            response = await axios.put( APIURL+"/customer_goal/permitted_task", formData);
        } else {
            response = await axios.post( APIURL+"/customer_goal/permitted_task", formData);
        }
        
        if(response.status !== 200){
            alert("There was an error saving the permitted task. Please try again.")
        } else {
            alert("Permitted Task Type created successfully.")
        }

        if(props.onSubmit){props.onSubmit()}
    };

    const CustomerGoalStatusMap = {
        0: 'Not Started',
        1: 'In Progress',
        2: 'Completed',
    };

    return (
        <div>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="customer-autocomplete"
                    options={customers}
                    getOptionLabel={(option) => option.customer_name + ` (${option.id})`}
                    value={customers.find(customer => customer.id === formData.customer_id) || null}
                    onChange={(event, newValue) => {
                        setFormData({ ...formData, customer_id: newValue ? newValue.id : '' });
                    }}
                    filterOptions={(options, { inputValue }) => {
                        return options.filter(option => option.customer_name.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Customer" variant="outlined" required/>
                    )}
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="customerGoal-autocomplete"
                    options={customerGoals}
                    getOptionLabel={(option) => option.goal_title + ` (${option.customer_goal_id})`}
                    value={customerGoals.find(customerGoal => customerGoal.customer_goal_id === formData.customer_goal_id) || null}
                    onChange={(event, newValue) => {
                        setFormData({ ...formData, customer_goal_id: newValue ? newValue.customer_goal_id : '' });
                    }}
                    filterOptions={(options, { inputValue }) => {
                        return options.filter(option => option.goal_title.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Customer Goal" variant="outlined" required/>
                    )}
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="taskProcCode-autocomplete"
                    options={taskProcCodes.filter(taskProcCode => taskProcCode.procTaskType != null)}
                    getOptionLabel={(option) => option.procTaskType ? option.procTaskType : ''}
                    value={taskProcCodes.find(taskProcCode => taskProcCode.taskProcCode_id === formData.taskProcCode_id) || null}
                    onChange={(event, newValue) => {
                        setFormData({ ...formData, taskProcCode_id: newValue ? newValue.taskProcCode_id : '' });
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Permitted Task Type" variant="outlined" required/>
                    )}
                />
                <FormHelperText>Task Types must be mapped to a procedure code in your company's settings to appear as an option here.</FormHelperText>
            </FormControl>

            <TextField
                name="goal_reference"
                label="Goal Reference"
                value={formData.goal_reference}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 255 }}
            />
            <TextField
                name="billable_to"
                label="Billable To"
                value={formData.billable_to}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 255 }}
            />
            <TextField
                name="provider_assigned"
                label="Provider Assigned"
                value={formData.provider_assigned}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 255 }}
            />
            <TextField
                name="location_assigned"
                label="Location Assigned"
                value={formData.location_assigned}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 255 }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Start Date"
                    value={moment(formData.start_date)}
                    onChange={(date) => setFormData({ ...formData, start_date: date })}
                    slotProps={{ textField: { margin: 'normal', fullWidth: true } }}
                />
                <DatePicker
                    label="End Date"
                    value={moment(formData.end_date)}
                    onChange={(date) => setFormData({ ...formData, end_date: date })}
                    slotProps={{ textField: { margin: 'normal', fullWidth: true } }}
                />
            </LocalizationProvider>
            <TextField
                name="total_cost"
                label="Total Cost $"
                value={formData.total_cost}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                type="number"
                inputProps={{ 
                    step: "0.01",
                    min: "0",
                    maxLength: 255
                }}
            />

            <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '20px' }}>
                Save
            </Button>
        </div>
    );
}

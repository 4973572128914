import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormRenderer from "../forms/JsonForm/FormRenderer/FormRenderer";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { APIURL } from '../../config';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        // color: '#ccc',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: 'black',
    },
}));

const CheckoutForms = (props) => {
    const [open, setOpen] = useState(false);
    const [customerGoals, setCustomerGoals] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal/getCustomerGoalsByTaskType`, {
                params: { customer_id: props.customer_id, task_id: props.task_id }
            })).data;
            setCustomerGoals(response);
        }
        fetchData();
    }, []);

    return (
        <Box>
            {props.forms.map(form =>
                <Box
                    key={form.form_id}
                    onClick={() => {
                        props.setSelectedForm(form);
                        setOpen(true);
                    }}
                    sx={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        margin: "5px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>{form.form_name}</Box>
                    {props?.completedForms?.includes(form.form_id) ?
                        <CheckCircleOutlinedIcon /> : 
                        null
                    }
                </Box>
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth='md'
                fullWidth
                scroll='body'
                disableRestoreFocus
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                    <div>
                        {props.selectedForm?.form_name}
                    </div>
                    <div>
                        <BootstrapTooltip title={
                            customerGoals ?
                                customerGoals.map((cg, index) => {
                                    return (
                                        <div key={cg.customer_goal_id} style={{ padding: 10 }}>
                                            <h4>{index + 1}{') '}{cg.goal_title}: </h4>
                                            <h5>{cg.goal_detail}</h5>
                                        </div>
                                    )
                                }) :
                                <h4>No goals for this customer yet.</h4>
                        } placement="right-start">
                            <div className='customer_goal_icon'>CG</div>
                        </BootstrapTooltip>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <FormRenderer
                        form_id={props.selectedForm?.form_type ? props.selectedForm?.form_type : props.selectedForm?.form_id}
                        json_schema={props.selectedForm?.json_schema}
                        ui_schema={props.selectedForm?.ui_schema}
                        styles={{ width: "100%", minWidth: "600px" }}
                        onSubmit={(form) => { setOpen(false); if(props.onSubmit) props.onSubmit(form); }}
                        onEdit={props.onEdit}
                        customer_id={props.customer_id}
                        task_id={props.task_id}
                        user_id={props.user_id}
                        readOnly={props.readOnly}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default CheckoutForms;
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, Grid, ListItemText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

function ScheduleSessionModal(props) {
  const [scheduleName, setScheduleName] = useState('');
  const [resource, setResource] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [maxCapacity, setMaxCapacity] = useState('');
  const [notes, setNotes] = useState('');
  const [taskType, setTaskType] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({
    scheduleName: false,
    resource: false,
    employees: false,
    selectedDate: false,
    startTime: false,
    endTime: false,
    maxCapacity: false,
    taskType: false,
  });

  const validate = () => {
    const newErrors = {
      scheduleName: !scheduleName,
      resource: !resource,
      employees: employees.length === 0,
      selectedDate: !selectedDate,
      startTime: !startTime || moment(startTime).isSameOrAfter(endTime),
      endTime: !endTime || moment(endTime).isSameOrBefore(startTime),
      maxCapacity: !maxCapacity || maxCapacity <= 0,
      taskType: !taskType,
    };
    setErrors(newErrors);
    setSubmitted(true);
    return Object.values(newErrors).every(error => !error);
  };

  const handleScheduleNameChange = (event) => {
    setScheduleName(event.target.value);
  };

  const handleResourceChange = (event) => {
    const selectedResource = props.resources.find(r => r.resource_id === event.target.value);
    setResource(selectedResource);
    setMaxCapacity(selectedResource?.capacity || '');
    setErrors({ ...errors, resource: selectedResource === '', maxCapacity: selectedResource?.capacity <= 0 });
  };

  const handleEmployeesChange = (event, newValue) => {
    if (props.isAdmin) {
      setEmployees(newValue);
    } else {
      setEmployees(newValue ? [newValue] : []);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    let date = moment(selectedDate);
    let session = { 
      schedule_name: scheduleName,
      resource_id: resource.resource_id,
      employee_ids: employees.map(emp => emp.id),
      date: date.format('YYYY-MM-DD'),
      start_time: moment(startTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
      end_time: moment(endTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
      max_capacity: maxCapacity,
      task_type: taskType,
      notes: notes ? notes : null,
      company_id: props.comp_id,
      created_by: props.user.emp_id,
    };
    
    try {
      let response = await axios.post(APIURL + '/master_schedule', session);
      props.reloadScheduleData();
      props.onClose();
      props.onSuccess("Session scheduled successfully");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          props.onError(error.response.data.message);
        } else {
          props.onError("An error occurred while scheduling session");
        }
      } else {
        props.onError("An error occurred while scheduling session");
      }
    }
  };

  useEffect(() => {
    if(!props.open) {
      setScheduleName('');
      setResource('');
      setEmployees([]);
      setSelectedDate(new Date());
      setStartTime(new Date());
      setEndTime(new Date());
      setMaxCapacity('');
      setNotes('');
      setTaskType('');
      setSubmitted(false);
      setErrors({
        resource: false,
        employee: false,
        selectedDate: false,
        startTime: false,
        endTime: false,
        maxCapacity: false,
        taskType: false,
      });
    } else {
      const defaultEmployee = props.employees?.find((employee) => employee.id === props.user.emp_id);
      setEmployees(defaultEmployee ? [defaultEmployee] : []);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.slotScheduleInfo) {
      props.slotScheduleInfo.startTime ? setStartTime(props.slotScheduleInfo.startTime) : setStartTime(new Date());
      props.slotScheduleInfo.endTime ? setEndTime(props.slotScheduleInfo.endTime) : setEndTime(new Date());
      props.slotScheduleInfo.startTime ? setSelectedDate(props.slotScheduleInfo.startTime) : setSelectedDate(new Date());
      if (props.slotScheduleInfo.resource) {
        setResource(props.slotScheduleInfo.resource);
        setMaxCapacity(props.slotScheduleInfo.resource.capacity);
      } else {
        setResource('');
        setMaxCapacity('');
      }
    }
  }, [props.slotScheduleInfo]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Schedule New Session
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            label="Session Name"
            fullWidth
            margin="normal"
            value={scheduleName}
            onChange={handleScheduleNameChange}
            error={errors.scheduleName && submitted}
            inputProps={{ maxLength: 50 }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="resource-select-label">Resource</InputLabel>
            <Select
              labelId="resource-select-label"
              value={resource.resource_id || ''}
              onChange={handleResourceChange}
              error={errors.resource && submitted}
            >
              {props.resources.filter(r => r.resource_is_active).map((resource) => (
                <MenuItem key={resource.resource_id} value={resource.resource_id}>
                  {resource.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="task-type-select-label">Task Type</InputLabel>
            <Select
              labelId="task-type-select-label"
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              error={errors.taskType && submitted}
            >
              {props.taskTypes.map((taskType, index) => (
                <MenuItem key={index} value={taskType}>
                  {taskType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              multiple={props.isAdmin}
              id="employee-select"
              options={props.employees}
              getOptionLabel={(option) => option.employee_name}
              renderInput={(params) => <TextField {...params} label={props.isAdmin ? "Employees" : "Employee"} error={errors.employees && submitted} />}
              onChange={handleEmployeesChange}
              value={props.isAdmin ? employees : employees[0] || null}
              disabled={!props.isAdmin && employees.length >= 1}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    <ListItemText primary={option.employee_name} />
                  </li>
                )
              }}
            />
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mt: 2, mb: 2 }}> {/* Adjusted spacing here */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <DatePicker
                    label="Select Date"
                    value={moment(selectedDate).isValid() ? new Date(selectedDate) : null}
                    onChange={setSelectedDate}
                    slotProps={{ textField: { fullWidth: true } }}
                    error={errors.selectedDate && submitted}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Max Capacity"
                    type="number"
                    value={maxCapacity}
                    onChange={(e) => setMaxCapacity(e.target.value)}
                    fullWidth
                    inputProps={{ max: resource?.capacity || '', min: 1 }}
                    error={errors.maxCapacity && submitted}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={setStartTime}
                  slotProps={{ textField: { fullWidth: true, error: errors.startTime && submitted } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={setEndTime}
                  slotProps={{ textField: { fullWidth: true, error: errors.startTime && submitted } }}
                  error={errors.endTime && submitted}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
          <TextField
            margin="normal"
            fullWidth
            label="Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            inputProps={{ maxLength: 255 }}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Schedule Session
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ScheduleSessionModal;

import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { APIURL } from '../../../config';
import axios from 'axios'
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Button } from 'react-bootstrap'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export default function TimesheetForm(props){
  const [editTimesheetTaskData, setEditTimesheetTaskData] = useState({});

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectLocation, setSelectedProjectLocation] = useState(null);

  const [taskTitle, setTaskTitle] = useState('');

  const [taskTypes, setTaskTypes] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState(null);

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
  const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss'));
  const [duration, setDuration] = useState("");
  const durations = Array.from({ length: 12 * 4 }, (_, i) => {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCompanyEmployeesAndTaskTypes = async () => {
      await axios.get(`${APIURL}/employees`, {
          params: {
              com_id: props.user.comp_id,
          }
      })
      .then(response => {
          let employees = response.data.filter(employee => employee.emp_is_active === true);
          setEmployees(employees.sort((a, b) => a.emp_first_name.localeCompare(b.emp_first_name)));
          if(props.employee_id){
            setSelectedEmployee(employees.find(employee => employee.emp_id === props.employee_id));
          }
      })

      await axios.get(`${APIURL}/dropdowns/dropdownType/taskType`, {
        params: {
            comp_id: props.user.comp_id,
            employeeType: props.user.emp_type
        }
      })
      .then(response => {
          let newTaskTypes = response.data;
          setTaskTypes(newTaskTypes);
      })
  }

const fetchEditTimesheetData = async () => {
  await axios.get(`${APIURL}/timesheets/v2`, {
      params: {
          task_id: props.task_id,
      }
  })
  .then(response => {
      let timeSheetData = response.data[0] ? response.data[0] : {};
      setEditTimesheetTaskData(timeSheetData);
      setSelectedEmployee(employees.find(employee => employee.emp_id === timeSheetData.tas_emp_id));
      setSelectedProject(projects.find(project => project.pro_id === timeSheetData.tas_pro_id));
      setSelectedTaskType(timeSheetData.tas_type);
  })
  }

  if(props.task_id){
    fetchEditTimesheetData();
  }
  fetchCompanyEmployeesAndTaskTypes();
  }, []);

  useEffect(() => {
    if(employees.length > 0 && props.task_id){
      setSelectedEmployee(employees.find(employee => employee.emp_id === editTimesheetTaskData.tas_emp_id));
    }

    if(projects.length > 0 && props.task_id){
      setSelectedProject(projects.find(project => project.pro_id === editTimesheetTaskData.tas_pro_id));
    }

    if(editTimesheetTaskData.tas_id){
      setTaskTitle(editTimesheetTaskData.tas_title);
      setStartTime(moment(editTimesheetTaskData.empLocdate.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm:ss'));
      setEndTime(moment(editTimesheetTaskData.checkoutEmpDate.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm:ss'));
      setDuration(moment.utc(moment(endTime, 'YYYY-MM-DDTHH:mm:ss').diff(moment(startTime, 'YYYY-MM-DDTHH:mm:ss'))).format('HH:mm'));
      setSelectedDate(moment(editTimesheetTaskData.empLocdate.replace(/Z/g, "")).format('YYYY-MM-DD'));
    }
  }, [employees,projects,editTimesheetTaskData]);

  useEffect(() => {
    const fetchData = async () => {
      await axios.get(`${APIURL}/project`, {
          params: {
              comp_id: props.user.comp_id,
              emp_id: selectedEmployee ? selectedEmployee.emp_id : null
          }
      })
      .then(response => {
          let newProjects = response.data;
          setProjects(newProjects);
      })
  }
  if(selectedEmployee) {fetchData()}
  }, [selectedEmployee]);

  useEffect(() => {
    const fetchData = async () => {
      await axios.get(`${APIURL}/projlocations`, {
        params: {
            project_id: selectedProject ? selectedProject.pro_id : null
        }
        })
        .then(response => {
            let newProjectLocation = response.data.length > 0 ? response.data[0] : null;
            setSelectedProjectLocation(newProjectLocation);
        })
      }
    fetchData();
  }, [selectedProject]);

  useEffect(() => {
    if(props.task_id){
      const newStartTime = moment(editTimesheetTaskData.empLocdate?.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm:ss');
      const newEndTime = moment(editTimesheetTaskData.checkoutEmpDate?.replace(/Z/g, "")).format('YYYY-MM-DDTHH:mm:ss');
      const newDuration = moment.utc(moment(newEndTime, 'YYYY-MM-DDTHH:mm:ss').diff(moment(newStartTime, 'YYYY-MM-DDTHH:mm:ss'))).format('HH:mm');
      setDuration(newDuration);

      if(props.user.comp_settings.some(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === "primaryBusinessType")){
        const [hours, minutes] = newDuration.split(':').map(Number);
        setStartTime(moment(selectedDate).set({hour: 9, minute: 0}).format('YYYY-MM-DDTHH:mm:ss'));
        setEndTime(moment(selectedDate).set({hour: 9 + hours, minute: minutes}).format('YYYY-MM-DDTHH:mm:ss'));
      } else {
        setStartTime(newStartTime);
        setEndTime(newEndTime);
      }
    }
  }, [selectedDate]);

  const submitNewTimesheet = async () => {
    if(isSubmitting) return;
    try{

      if (!selectedEmployee || !selectedProject || !selectedProjectLocation || !taskTitle || !selectedTaskType || !startTime || !endTime) {
        alert('Please fill out all fields 1');
        return;
      }
      
      if(props.task_id){
        if ( !taskTitle || !startTime || !endTime) {
          alert('Please fill out all fields 2');
          return;
        }

        let data = {
          task_id: props.task_id,
          title: taskTitle,
          startTime: startTime,
          endTime: endTime,
        };
        let timesheetUpdateResponse = await axios.put(APIURL + '/timesheets/v2', data)

        console.log(timesheetUpdateResponse.status)
        if (timesheetUpdateResponse.status === 200) {
          props.onSubmit();
        }
        else {
          alert('An error occurred while updating the timesheet.');
        }

        return;
      }

      let data = {
        empId: props.user.emp_id,
        empName: selectedEmployee.emp_first_name + ' ' + selectedEmployee.emp_last_name,
        company_id: props.user.comp_id,
        tTitle: taskTitle,
        tDesc: 'Time Sheet Entry',
        tProject: selectedProject.pro_id,
        tPreReq: 'NULL',
        tType: selectedTaskType,
        tStrtDate: moment(startTime).format('YYYY-MM-DD'),
        tEndDate: moment(endTime).format('YYYY-MM-DD'),
        tStartTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ss'),
        tEndTime: moment(endTime).format('YYYY-MM-DDTHH:mm:ss'),
        tLoc: selectedProjectLocation.loc_id,
        tActive: 1,
        tAssTo: selectedEmployee.emp_id,
        tPriority: 'Low',
        tIsBillable: true,
        tNotes: 'N/A',
        user_id: props.user.emp_id
      };

      let tasResponse = await axios.post(APIURL + '/task', data)

      if (tasResponse.data.code === 200) {
        let empLocateData = {
            assignedById: props.user.emp_id,
            projId: selectedProject.pro_id,
            empId: selectedEmployee.emp_id,
            empName: selectedEmployee.emp_first_name + ' ' + selectedEmployee.emp_last_name,
            companyId: props.user.comp_id,
            taskName: taskTitle,
            locId: selectedProjectLocation.loc_id,
            taskId: tasResponse.data.id,
            emploc_lat: parseFloat(selectedProjectLocation.location_lat),
            emploc_lon: parseFloat(selectedProjectLocation.location_lon),
            start_time: startTime,
            end_time: endTime,
            timesheet_status: 1
        }

        let empLocateResponse = await axios.post(APIURL + '/timesheets', { ...empLocateData })

        if (empLocateResponse.data.code === 200) {
          props.onSubmit();
        }
        else {
          alert('An error occurred while creating the timesheet.');
        }
      }
    } catch(err){
      alert('An error occurred while creating the timesheet.');
    } finally {
      console.log('HERE!')
    }
  }

  return (
    <div style={{ display: 'grid', gap: '20px' }}>
      <h4>Timesheet</h4>

      <Autocomplete
        disabled={props.employee_id || props.task_id}
        options={employees}
        value={selectedEmployee}
        getOptionLabel={(option) => `${option.emp_first_name} ${option.emp_last_name} (${option.emp_id})`}
        onChange={(event, newValue) => setSelectedEmployee(newValue)}
        renderInput={(params) => <TextField {...params} label="Employee" />}
      />

      <Autocomplete
        disabled={props.task_id}
        options={projects}
        value={selectedProject}
        getOptionLabel={(option) => `${option.pro_title} (${option.pro_id})`}
        onChange={(event, newValue) => setSelectedProject(newValue)}
        renderInput={(params) => <TextField {...params} label="Project" />}
      />

      <TextField
        label="Task Title"
        value={taskTitle}
        onChange={(e) => setTaskTitle(e.target.value)}
        inputProps={{ maxLength: 50 }}
      />

      <Autocomplete
        hidden={props.task_id}
        options={taskTypes}
        value={selectedTaskType}
        getOptionLabel={(option) => option}
        onChange={(event, newValue) => setSelectedTaskType(newValue)}
        renderInput={(params) => <TextField {...params} label="Task Type" />}
      />
      
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Timesheet Date"
          value={moment(selectedDate)}
          onChange={(newValue) => setSelectedDate(newValue)}
          slotProps={{ textField: { hidden: !props.user.comp_settings.some(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === "primaryBusinessType")} }}
        />
      </LocalizationProvider>      

      <FormControl hidden={!props.user.comp_settings.some(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === "primaryBusinessType")}>
        <InputLabel id="duration-label">Duration</InputLabel>
        <Select
          labelId="duration-label"
          value={duration}
          onChange={(e) => {
            setDuration(e.target.value);
            const [hours, minutes] = e.target.value.split(':').map(Number);
            setStartTime(moment(selectedDate).set({hour: 9, minute: 0}).format('YYYY-MM-DDTHH:mm:ss'));
            setEndTime(moment(selectedDate).set({hour: 9 + hours, minute: minutes}).format('YYYY-MM-DDTHH:mm:ss'));
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 8,
              },
            },
          }}
        >
          {durations.map((duration) => (
            <MenuItem key={duration} value={duration}>{duration}</MenuItem>
          ))}
        </Select>
      </FormControl>

     <TextField
        hidden={props.user.comp_settings.some(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === "primaryBusinessType")}
        id="start-time"
        label="Start Time"
        type="datetime-local"
        value={moment(startTime).format('YYYY-MM-DDTHH:mm')}
        onChange={(e) => {
          setStartTime(moment(e.target.value).format('YYYY-MM-DDTHH:mm[:00]'));
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: '100%' }}
      />

      <TextField
        hidden={props.user.comp_settings.some(setting => (setting.feature_id === 101 || setting.feature_id === 104) && setting.feature_value === "primaryBusinessType")}
        id="end-time"
        label="End Time"
        type="datetime-local"
        value={moment(endTime).format('YYYY-MM-DDTHH:mm')}
        onChange={(e) => {
          const [hours, minutes] = e.target.value.split(':').map(Number);
          setEndTime(moment(e.target.value).format('YYYY-MM-DDTHH:mm[:00]'));
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: '100%' }}
      />

      <div style={{ textAlign: 'right' }}>
          <Button 
            disabled={isSubmitting}
            className="FW-Button" 
            onClick={async () => {
              setIsSubmitting(true);
              await submitNewTimesheet();
              setSelectedProject(null);
              setSelectedTaskType(null);
              setTaskTitle('');
              setTimeout(() => {setIsSubmitting(false);}, 2000);
              
            }}
            style={{ width: '150px' }}
          >
            Submit
          </Button>
      </div>
    </div>
  );
}
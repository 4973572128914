import React, { Component } from 'react';
import HandyCard from '../../HandyCard/HandyCard';
import { Form, FormControl, Button, Modal } from 'react-bootstrap';
import "./Files.css";
import axios from 'axios';
import { APIURL } from '../../../config'
import moment from 'moment'
import { Card, CardActions, CardContent, IconButton, Dialog, DialogActions, DialogContent } from '@mui/material'
import UplaodForm from '../../../components/forms/UploadForm/UploadForm';
import CloseIcon from '@mui/icons-material/Close';
import FilterSortBy from '../FilterSortBy/FilterSortBy';
import DocumentViewerModal from '../../Documents/Modals/DocumentViewerModal';

class Files extends Component {
    state = {
        grid: false,
        artifacts: null,
        artifacts_display: null,
        taskTypeList: [],
        empList: [],
        searchTerm: '',
        filter: { class: '', specifier: '' },
        deleteArt: null,
        show_upload: false,
        show: false,
        infoID: null,
        docViewerShow: false,
        fileUrl: '',
        fileExt: ''
    }

    handleShow = (id) => { this.setState({ show: true, infoID: id }) };
    handleClose = () => { this.setState({ show: false }) };

    setArtifacts = (artifacts) => {
        this.setState({ ...this.state, artifacts_display: artifacts });
    }

    changeToGrid = () => {
        this.setState({ grid: true })
    }

    changeToRows = () => {
        this.setState({ grid: false })
    }

    renderGrid = () => {
        let content = []
        content = this.state.artifacts_display.map((item) => {
            const file = item.url.split('/')[4]
            // const fileName = file.split('.')[0]
            const fileType = file.split('.')[1]
            return <HandyCard
                key={item.id}
                id={item.id}
                fileName={item.file_name}
                fileType={fileType}
                taskName={item.tas_title}
                empName={item.empName}
                url={item.url}
                artifact={true}
                date={item.submitted}
                pname={item.pro_title}
                del={this.delArt}
                grid
            />
        })
        return content
    }

    renderList = () => {
        if (this.state.artifacts_display) {
            return this.state.artifacts_display.map((item, indx) => {
                const file = item.url.split('/')[4]
                const fileType = file.split('.')[1]
                return (
                    <Card sx={{ marginBottom: '10px', height: '40px' }}>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'start' }}>
                                <div style={{ width: '50%' }}>
                                    <a href={'#'} onClick={() => this.setState({ docViewerShow: true, fileUrl: item.url, fileExt: fileType })}>{item.file_name}</a>
                                </div>
                                <div style={{ width: '20%' }}>{item.empName}</div>
                                <div style={{ width: '20%' }}>{moment(item.submitted).format("MMMM DD, YYYY @ HH:mm A")}</div>
                                <div style={{ width: '10%', display: 'flex', justifyContent: 'end' }} className="text-muted">
                                    <div style={{ marginRight: '10px' }}>
                                        <i
                                            onClick={() => this.handleShow(item.id)}
                                            className="fas fa-info-circle"
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </div>
                                    <div>
                                        <i
                                            data-toggle="modal"
                                            data-target="#staticBackdropDel"
                                            onClick={() => this.delArt(item.id)}
                                            className="fas fa-trash px-2"
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <CardActions sx={{}}>
                            <Modal show={this.state.show && item.id === this.state.infoID} onHide={() => this.handleClose()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{item.file_name}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <small className="text-muted">Task</small>
                                    <p>{item.tas_title}</p>
                                    <small className="text-muted">Project</small>
                                    <p>{item.pro_title}</p>
                                    <small className="text-muted">Document Type</small>
                                    <p>{fileType}</p>
                                    <small className="text-muted">Uploaded on</small>
                                    <p>{moment(item.submitted).format("MMM DD, HH:mm a")}</p>
                                    <small className="text-muted">Uploaded by</small>
                                    <p>{item.empName}</p>
                                </Modal.Body>
                            </Modal>
                        </CardActions>
                    </Card>
                )
            })
        } else {
            return <div>Loading...</div>
        }

    }

    async fetchData() {
        // change to asYnc        

        let artifactsData = await axios.get(APIURL + "/artifacts?cus_id=" + this.props.cus_id)
        let empList = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } })
        let taskType = await axios.get(APIURL + '/dropdowns/dropdownType/taskType', { params: { comp_id: this.props.comp_id } })
        let artifacts = artifactsData.data.map((art) => {
            const employee = empList.data.find(emp => art.employee_id === emp.id)

            if (!employee) {
                return { ...art, empName: " " }
            }

            return { ...art, empName: employee.employee_name }
        })

        this.setState({
            artifacts: artifacts,
            artifacts_display: artifacts.filter((art) => !!art.show).sort((a, b) => new Date(b.submitted) - new Date(a.submitted)),
            empList: empList.data,
            taskTypeList: taskType.data
        })
    }

    async componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.show_upload !== this.state.show_upload) {
            this.fetchData()
        }
    }

    delArtifact = async () => {
        let delArt = await axios.delete(APIURL + '/artifacts', { data: { id: this.state.deleteArt.id } })
        if (delArt.data.code === 200) {
            let artifactsArr1 = this.state.artifacts.filter((art) => art.id !== this.state.deleteArt.id)
            let artifactsArr2 = this.state.artifacts_display.filter((art) => art.id !== this.state.deleteArt.id)

            this.setState({ artifacts_display: artifactsArr2, artifacts: artifactsArr1, deleteArt: null })
        } else {
            console.log('Artifact not deleted', delArt.data)
        }
    }

    delArt = (id) => {
        let art = this.state.artifacts_display.find(((art) => art.id === id))
        if (!!art) {
            this.setState({ deleteArt: art })
        }
    }

    searchArtHandler = (e) => {
        var searchQuery = e.target.value
        console.log(searchQuery)
        this.setState({ searchTerm: e.target.value })
    }

    searchArtResults = (e) => {
        e.preventDefault()
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        console.log(searchTerm)
        let artSearchResults = this.state.artifacts.filter((art) => !!art.show).map((art) => {
            if (searchTerm.exec(art.file_name) || searchTerm.exec(art.tas_title) || searchTerm.exec(art.empName)) {
                return art
            }
            else {
                return null
            }
        }).filter((art) => !!art)

        this.setState({ artifacts_display: artSearchResults })
    }
    render() {
        // let empList = this.state.empList.map((emp) => <option key={emp.emp_id} value={emp.emp_id}>{emp.emp_first_name} {emp.emp_last_name}</option>)
        // let taskList = !this.props.taskList ? [] : this.props.taskList.map((task) => <option key={task.tas_id} value={task.tas_id}>{task.tas_title}</option>)
        let taskTypeList = this.state.taskTypeList.map((type, index) => <option key={index} value={type}>{type}</option>)
        let content = (<div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '300px', margin: 5 }}>
            <img src='./projectgraphic.png' alt='ArtTab' style={{ width: '10rem', height: '10rem' }} />
            <h5 style={{ fontFamily: 'Lato', fontSize: 18 }}>No Artifacts found </h5>
            <p style={{ fontFamily: 'Lato', fontSize: 12 }} className='text-muted'>We could not find any artifacts in this projects. Please add files via the Fieldworker mobile app to view them.</p>
        </div>)
        if (this.state.grid && this.state.artifacts_display) {
            if (this.state.artifacts_display.length > 0) {
                content = (
                    <div className="d-flex row justify-content-around overflow-auto" style={{ height: '700px' }}>
                        {this.renderGrid()}
                    </div>
                )
            } else {
                content = (<div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '300px', margin: 5 }}>
                    <img src='./projectgraphic.png' alt='ArtTab' style={{ width: '10rem', height: '10rem' }} />
                    <h5 style={{ fontFamily: 'Lato', fontSize: 18 }}>No Artifacts found </h5>
                    <p style={{ fontFamily: 'Lato', fontSize: 12 }} className='text-muted'>We could not find any artifacts in this projects. Please add files via the Fieldworker mobile app to view them.</p>
                </div>)
            }

        } else if (!this.state.grid && this.state.artifacts_display) {
            if (this.state.artifacts_display.length > 0) {
                content = (
                    <div className='overflow-auto' style={{ height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ width: '50%' }}>FILE NAME</p>
                            <p style={{ width: '20%' }}>UPLOADED BY</p>
                            <p style={{ width: '20%' }}>UPLOADED DATE</p>
                            <p style={{ width: '10%' }}></p>
                        </div>
                        {this.renderList()}
                    </div>
                )
            } else {
                content = (<div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '300px', margin: 5 }}>
                    <img src='./projectgraphic.png' alt='ArtTab' style={{ width: '10rem', height: '10rem' }} />
                    <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 48, marginBottom: 28 }}>No Artifacts found </h5>
                    <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>We could not find any artifacts in this projects. Please add files via the Fieldworker mobile app to view them.</p>
                </div>)
            }
        } else {
            content = <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div></div>
        }
        return (
            <div className="TasksPage"> {/* reused class from tasks page*/}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-7px' }} className="TopPanel"> {/* reused class from tasks page*/}
                    <div>
                        <Form inline style={{ width: '145%' }} onSubmit={(e) => this.searchArtResults(e)}>
                            <FormControl onChange={(e) => this.searchArtHandler(e)} value={this.state.searchTerm} type="text" placeholder="Search" className="form-control w-50 mr-1" />
                            <Button type="submit" variant="outline-primary" style={{ border: "solid 1px #95D0EB", marginLeft: "-5px", borderRadius: '0 5px 5px 0', paddingRight: "15px", paddingLeft: "15px" }}><i className="fas fa-search"></i></Button>
                            <Button inline style={{ marginLeft: '10px' }} onClick={() => { this.setState({ ...this.state, show_upload: true }) }}>
                                Upload
                            </Button>
                        </Form>
                    </div>
                    <FilterSortBy artifacts={this.state.artifacts} artifactsDisplay={this.state.artifacts_display} setArtifacts={this.setArtifacts} taskList={'taskList'} taskTypeList={taskTypeList} empList={'empList'} />
                </div>
                <div className='tasks_info'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h5>Files</h5>
                        </div>
                        <div className="Artifacts">
                            <ul>
                                <li onClick={() => this.changeToGrid()}><i className="fas fa-th"></i></li>
                                <li onClick={() => this.changeToRows()}><i className="fas fa-list"></i></li>
                            </ul>
                        </div>
                    </div>
                    {content}
                </div>
                <Dialog open={this.state.show_upload} onClose={() => { this.setState({ ...this.state, show_upload: false }) }}>
                    <DialogActions>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => { this.setState({ ...this.state, show_upload: false }) }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px', width: 370 }}>
                        <UplaodForm company_id={this.props.comp_id} mode='add' user={this.props.user} customer_id={this.props.cus_id} onSubmit={() => { this.setState({ ...this.state, show_upload: false }) }}></UplaodForm>
                    </DialogContent>
                </Dialog>
                <DocumentViewerModal
                    open={this.state.docViewerShow}
                    handleClose={() => this.setState({ docViewerShow: false })}
                    fileUrl={this.state.fileUrl}
                    fileExt={this.state.fileExt}
                />
                <div className="modal fade" id="staticBackdropDel" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h5 className="modal-title" id="staticBackdropLabel">Delete Artifact {!this.state.deleteArt ? '' : this.state.deleteArt.file_name}</h5>
                                <hr className="HorizontalLine"></hr>
                                <div className="d-flex flex-row justify-content-center">
                                    <button onClick={() => { }} type="button" className="btn btn-outline-info m-1" data-dismiss="modal">Cancel</button>
                                    <button onClick={() => this.delArtifact()} type="button" className="btn btn-info m-1" data-dismiss="modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Files;
import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Button } from '@mui/material';
import { APIURL } from '../../config';
import axios from 'axios';
import moment from 'moment';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const EVV_NJ_SUBMISSION_REVIEW = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf('day').add(-3,'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
  const [evvSubmissionData, setEvvSubmissionData] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [updateData, setUpdateData] = useState(true);

  const columns = [
    { field: 'id', headerName: 'Task ID', flex: 1, hide:true },
    {
      field: 'toggle',
      headerName: 'Send In Next Run?',
      flex: 1.1,
      renderCell: (params) => (
        <ToggleButton
          sx={{ fontSize: '12px', height: '70%' }}
          value="check"
          selected={params.row.toggle}
          onChange={() => {
            handleToggle(params.row.id, params.row.toggle);
          }}
        >
          {params.row.toggle ? 'Sending' : 'Not Sending'}
        </ToggleButton>
      ),
    },
    { field: 'task_title', headerName: 'Task Title', flex: 1 },
    { field: 'task_type', headerName: 'Task Type', flex: 0.8 },
    { field: 'project_title', headerName: 'Project Title', flex: 1, hide:true },
    { field: 'employee_name', headerName: 'Caregiver', flex: 1, hide:true },
    { field: 'customer_name', headerName: 'Client', flex: 1, hide:true },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'errors', headerName: 'Errors', flex: 1 },
    { field: 'scheduled_start_time', headerName: 'Scheduled Start Time', flex: 1 },
    { field: 'scheduled_end_time', headerName: 'Scheduled End Time', flex: 1 },
    { field: 'actual_start_time', headerName: 'Actual Start Time', flex: 1 },
    { field: 'actual_end_time', headerName: 'Actual End Time', flex: 1 },
  ];

  useEffect(() => {
    if (updateData) {
      async function fetchData(){
        setIsLoading(true);

        await axios.get(APIURL+'/evv/status', {
          params: {
            company_id: props.user.comp_id, 
            start: startDate, 
            end: endDate
          }
        })
        .then(response => {
          let evvSubmissionData = response.data;
          setEvvSubmissionData(evvSubmissionData.data);
          setRows(
            evvSubmissionData ? evvSubmissionData.map((row) => {
            return {
              id: row.taskData.tas_id,
              task_title: row.taskData.tas_title,
              task_type: row.taskData.tas_type,
              project_title: row.taskData.pro_title,
              employee_name: row.taskData.emp_name,
              customer_name: row.taskData.cus_name,
              status: row.evv_status == 'Unknown' ? 'No Submission Found' : row.evv_status,
              scheduled_start_time: moment(row.taskData.scheduled_start_time.replace(/Z/g, "")).format('MMM DD YYYY [at] hh:mma'),
              scheduled_end_time: moment(row.taskData.scheduled_end_time.replace(/Z/g, "")).format('MM-DD-YY [at] hh:mma'),
              actual_start_time: moment(row.taskData.actual_start_time.replace(/Z/g, "")).format('MM-DD-YY [at] hh:mma'),
              actual_end_time: moment(row.taskData.actual_end_time.replace(/Z/g, "")).format('MM-DD-YY [at] hh:mma'),
              errors: Array.from(
                new Set(
                  row.evv_logs
                    .map(log => JSON.parse(log.errors))
                    .flat()
                    .map(error => error.message)
                )
              ).join(', ')
            };
            }) : []
          );
          setUpdateData(false);
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setUpdateData(false);
        });

        setIsLoading(false);
      }
      fetchData();
    }
  }, [startDate, endDate, props.user, updateData]);

  useEffect(() => {
    if(isLoading === false) {
      console.log(rows.length)
      let newRowsPromises = rows.map(async(row) => {
        let sendEVV = await axios.get(APIURL+'/evv/nj/sendEVV', {
          params: {
            task_id: row.id
          }
        })
        console.log(sendEVV.data)
        let newRow = {...row, toggle: sendEVV.data ? true : false}
        console.log(newRow)
        return newRow;
      })

      Promise.all(newRowsPromises).then(newRows => {
        setRows(newRows);
      });
    }
  }, [isLoading]);

  const handleToggle = async (task_id, currentToggleValue) => {
    let sendEVV = await axios.put(APIURL+'/evv/nj/sendEVV', {
      task_id: task_id,
      sendEVV: !currentToggleValue
    }).then(response => {
      if(response.status === 200){
        let newRows = rows.map(row => {
          if (row.id === task_id) {
            let newRow = {...row, toggle: !currentToggleValue}
            return newRow;
          } else {
            return row;
          }
        });
        setRows(newRows);
      }
    })
  }

  return (
    (<div style={{maxWidth: '90vw', padding: '10px'}}>
      <h4>EVV Submission Review</h4>
      <hr></hr>
      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={[moment(startDate), moment(endDate)]}
            onChange={(newValue) => {
              setStartDate(moment(newValue[0]).format('YYYY-MM-DD'));
              setEndDate(moment(newValue[1]).format('YYYY-MM-DD'));
            }}
            sx={{ marginLeft: '5px', width: '400px' }}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
        <Button 
          sx={{ height: '30px', marginLeft: '15px' }} 
          variant="contained" 
          onClick={() => setUpdateData(true)}
          disabled={updateData}
        >
          Refresh
        </Button>
      </div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        {isLoading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ marginTop: '20px' }}/>
            <p style={{ marginTop: '10px' }}>Please wait, your EVV submissions may take a few moments to load...</p>
          </div>
        ) : (
          <DataGrid
            initialState={{ density: 'compact' }}
            rows={rows}
            columns={columns}
            pageSize={10}
            pageSizeOptions={[10]}
            rowHeight={70}
            autoHeight
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
            }}
          />
        )}
      </div>
    </div>)
  );
}

export default EVV_NJ_SUBMISSION_REVIEW;
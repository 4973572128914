import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function FinancialDashboard(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [iframeUrl, setiframeUrl] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/metabase/financialDashboard", {
                params: { companyID: props.user.comp_id },
            })
            .then((response) => {
                setiframeUrl(response.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                <iframe
                    src={iframeUrl}
                    style={{ width: '100%', height: '120vh', border: 'none' }}
                    allowTransparency
                />
            </div>
        );
    };
};
import React, { useState, useEffect } from "react";
import CheckoutForms from "../../Checkout/CheckoutForms";
import "../../Checkout/CheckoutModal.css";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";

export default function ViewFormModal(props) {
    const [selectedForm, setSelectedForm] = useState(null);
    const [forms, setForms] = useState([]);

    useEffect(() => {
        setForms(props.forms);
    }, [props.forms]);
    

    return (
        <div>
            <Dialog 
                open={props.show} 
                onClose={props.hide} 
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Forms</DialogTitle>
                <DialogContent>
                    <CheckoutForms 
                        forms={forms} 
                        customer_id={props.cus_id}
                        task_id={props.task_id}
                        selectedForm={selectedForm}
                        setSelectedForm={setSelectedForm}
                        user_id={props.user_id}
                        readOnly={props.read ? true : false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.hide}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

